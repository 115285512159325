<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:33:35
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-22 14:54:07
 * @FilePath: /mediatom-web/src/components/GlobalHeader/AvatarDropdown.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div v-if="currentUser && currentUser.name">
    <a-dropdown>
      <span class="ant-pro-account-avatar">
        <rightUser v-if="!ismanager" class="user-svg"></rightUser>
        <AvatarSvg v-else class="avatar-svg" />
        <span
          v-if="!ismanager"
          class="word"
          :style="{ color: ismanager ? '#666' : '#cdd5e0', 'line-height': '20px' }"
        >{{ currentUser.name }}</span
        >
        <span v-else class="adx-word" :style="{ 'line-height': '20px' }">{{ currentUser.name }}</span>
        <ArrowSvg class="arrow-svg" v-if="ismanager" style="'line-height':100px " />
      </span>
      <template v-slot:overlay>
        <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
          <a-menu-item class="menu-item" key="account" v-if="ismanager" @click="handlePushTo('account')">
            <a-icon v-if="!ismanager" type="user" />
            个人信息
          </a-menu-item>
          <a-menu-item class="menu-item" key="pwd" v-if="ismanager" @click="handlePushTo('editpwd')">
            <a-icon v-if="!ismanager" type="lock" />
            修改密码
          </a-menu-item>
          <a-menu-item class="menu-item" key="apikey" v-if="ismanager && +isAdmin === 2" @click="handlePushTo('apikey')">
            <a-icon v-if="!ismanager" type="apikey-collect" />
            Key
          </a-menu-item>
          <a-menu-item class="menu-item" key="money" v-if="ismanager" @click="handlePushTo('financeinfo')">
            <a-icon v-if="!ismanager" type="money-collect" />
            财务信息
          </a-menu-item>
          <a-menu-item class="menu-item" key="logout" @click="handleLogout">
            <a-icon v-if="!ismanager" type="logout" />
            退出登录
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <!-- 删除弹窗 -->
    <m-delete-modal
      :isDelete="false"
      :delVisible="delVisible"
      title="退出登录"
      @modalCancel="delVisible = false"
      @handleDelete="handleDeleteItem"
      desc="确定要退出登录吗？"
    />
  </div>
  <span v-else>
    <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
  </span>
</template>

<script>
import rightUser from '@/assets/icons/right-user.svg?inline'
import ArrowSvg from '@/assets/icons/arrow.svg?inline'
import AvatarSvg from '@/assets/icons/avatar.svg?inline'
import { mapState } from 'vuex'
export default {
  name: 'AvatarDropdown',
  components: { rightUser, ArrowSvg, AvatarSvg },
  data () {
    return {
      ismanager: process.env.VUE_APP_ISMANAGER === 'true',
      delVisible: false
    }
  },
  computed: {
    ...mapState({
      allRoutes: (state) => state.permission.allRoutes,
      isAdmin: (state) => state.user.isAdmin
    })
  },
  props: {
    currentUser: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handlePushTo (name) {
      this.$router.push({ name })
    },
    handleToCenter () {
      this.$router.push({ path: '/account/center' })
    },
    handleToSettings () {
      this.$router.push({ path: '/account/settings' })
    },
    handleLogout (e) {
      this.delVisible = true
    },
    handleDeleteItem () {
      return this.$store.dispatch('Logout').then((res) => {
        this.$router.push('/user/login')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-account-avatar {
  display: inline-block;
  height: 100%;
  margin-right: 8px;
  .user-svg {
    width: 17px;
    height: 17px;
    margin-bottom: -3px;
    margin-right: 3px;
    color: #fff;
  }
  .arrow-svg {
    margin-left: 8px;
    margin-bottom: -5px;
    width: 20px;
    height: 20px;
  }
  .avatar-svg {
    padding: 2px;
    width: 28px;
    height: 28px;
    margin-top: 6px;
    margin-bottom: -8px;
    margin-right: 8px;
  }
  .adx-word {
    font-size: 16px;
    color: #5a607f;
  }
  &:hover {
    background: none !important;
  }
}
.menu-item {
  text-align: center;
}
</style>
