<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-19 16:32:58
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-22 19:08:12
 * @FilePath: /mediatom-web/src/layouts/UserLayoutForManager.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="userLayout" :class="['user-layout-wrapper']">
    <div class="container">
      <div class="user-layout-content">
        <div class="user-layout-content-header">
          <div class="logo">
            <img :src="loginIcon" alt="logo" />
          </div>
          <div class="title">
            <span>{{ $t('user.login.lay-text') }}</span>
          </div>
        </div>
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'
import { mapState } from 'vuex'
export default {
  name: 'UserLayout',
  components: {},
  mixins: [deviceMixin],
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
      loginIcon: (state) => state.autoweb.loginIcon,
      title: (state) => state.autoweb.title
      // isMedPro: (state) => state.autoweb.isMedPro
    })
  },
  mounted () {
    document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  }
}
</script>

<style lang="less" scoped>
@media (min-width: 998px) {
  #userLayout.user-layout-wrapper {
    .container {
      // min-width: 1280px;
      background: url(~@/assets/images/login/user-background-manage.png);
      background-size: 100% 100%;
      height: auto;
      width: auto;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      .user-layout-content {
        width: 510px;
        .user-layout-content-header{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          .logo{
            margin-top: 18px;
            img{
              max-height: 52px;
              min-height: 52px;
            }
          }
          .title{
            font-size: 18px;
            font-weight: 400;
            line-height: 33px;
            margin-top: 8px;
            margin-bottom: 15px;
            color: #5a607f;
          }
        }
        background: #fff;
        min-width: 30%;
        border-radius: 12px;
        padding: 0 32px;
      }
    }
  }
}
@media (max-width: 998px) {
  #userLayout.user-layout-wrapper {
    .container {
      width: 100%;
      display: flex;
      overflow: hidden;
      position: relative;
      background-color: #fff;
      .user-layout-content {
        background: #fff;
        width: 100%;
        border-radius: 12px;
        padding: 0 40px;
        .user-layout-content-header{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          .logo{
            margin-top: 60px;
            img{
              min-height: 60px;
              max-height: 60px;
            }
          }
          .title{
            font-size: 22px;
            font-weight: 400;
            line-height: 33px;
            margin-top: 18px;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
</style>
