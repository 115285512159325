import { platformLinkList } from '@/api/aggregate'
import {
  getAdminList,
  getPlatListByuser,
  getAppListByAdminId,
  getPlaceList,
  getPlaceListByAppIds,
  getGroupList,
  groupListByAdplace,
  getSourceList,
  getChannelList,
  getPositionList,
  getSourceListByAdplace,
  getProdAndpackageList
} from '@/api/common'
import { getAppList, getAppLists } from '@/api/media'
export default {
  data () {
    return {
      platList: [],
      adminList: [],
      appList: [],
      appLists: [],
      placeList: [],
      groupList: [],
      sourceList: [],
      channelList: [],
      positionList: [],
      packageList: [],
      productList: [],
      // 操作系统列表
      osList: [
        {
          name: '安卓',
          id: '1'
        },
        {
          name: 'IOS',
          id: '0'
        }
      ],
      // 素材类型列表
      typeList: [
        {
          name: '图片',
          id: '1'
        },
        {
          name: '视频',
          id: '2'
        }
      ]
    }
  },
  methods: {
    // 获取全部列表

    // 获取全部广告平台列表
    async getPlatList () {
      const resp = await platformLinkList()
      if (resp.code === 200) {
        this.platList = (resp.data || []).map((item) => ({
          ...item,
          name: item.name,
          id: +item.id
        }))
      }
    },
    // 获取账户列表
    async getAdminList () {
      const resp = await getAdminList()
      if (resp.code === 200) {
        this.adminList = resp.data || []
      }
    },
    // 获取全部应用列表
    async getAppLists (data) {
      const resp = await getAppLists(data)
      this.appLists = resp.data || []
    },
    // 获取全部应用列表
    async getAppList () {
      const resp = await getAppList()
      this.appList = resp.data || []
    },
    // 获取全部广告位列表
    async getPlaceList () {
      const resp = await getPlaceList()
      this.placeList = resp.data || []
    },

    // 获取全部流量分组列表
    async getGroupList () {
      const resp = await getGroupList()
      this.groupList = resp.data || []
    },
    // 获取全部广告源位列表
    async getSourceList () {
      const resp = await getSourceList()
      this.sourceList = resp.data || []
      this.sourceList.forEach((item) => {
        item.searchId = item.platPlaceId
      })
    },
    // 获取渠道列表
    async getChannelList () {
      const resp = await getChannelList()
      this.channelList =
        resp.data.map((item) => ({
          id: item,
          name: item
        })) || []
    },

    // 获取广告样式列表
    async getPositionList () {
      const resp = await getPositionList()
      this.positionList = resp.data || []
    },

    // 关联查询

    // 通过账户获取广告平台列表
    async getPlatListByuser (
      query = {
        id: ''
      }
    ) {
      const resp = await getPlatListByuser(query)
      if (resp.code === 200) {
        this.platList = (resp.data || []).map((item) => ({
          name: item.name,
          id: item.id
        }))
      }
    },
    // 通过账户查应用列表
    async getAppListByAdminId (id) {
      const resp = await getAppListByAdminId(id)
      if (resp.code === 200) {
        this.appList = resp.data || []
      }
    },
    // 通过应用id（数组）查广告位列表
    async getPlaceListByAppIds (ids = []) {
      const resp = await getPlaceListByAppIds(ids)
      if (resp.code === 200) {
        this.placeList = resp.data || []
      }
    },
    // 通过placeid（多个）查group列表
    async groupListByAdplace (ids = []) {
      const resp = await groupListByAdplace(ids)
      if (resp.code === 200) {
        this.groupList = resp.data || []
      }
    },
    // 通过广告位查询广告源
    async getSourceListByAdplace (ids = []) {
      const resp = await getSourceListByAdplace(ids)
      if (resp.code === 200) {
        this.sourceList = resp.data || []
        this.sourceList.forEach((item) => {
          item.searchId = item.platPlaceId
        })
      }
    },
    async getProdAndpackageList () {
      const { data = {} } = await getProdAndpackageList()
      const { productList = [], packageList = [] } = data
      this.packageList = packageList.map((item) => {
        return {
          id: item.productBundle,
          name: item.productName
        }
      })
      this.productList = productList.map((item) => {
        return {
          name: item,
          id: item
        }
      })
    }
  }
}
